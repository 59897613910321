.inlineDecorationImportBuiltIn::after {
    content: "(Built-In)";
    color: green;
}

.inlineDecorationImportLoading::after {
    content: "(Loading)";
    color: red;
}

.inlineDecorationImportCached::after {
    content: "(Cached)";
    color: green;
}